import axios from 'api/axios'
import { Roles } from 'enums/Roles'
import { formatDateString } from 'pages/RetrivalManager/CentersPage'
import { useEffect, useState } from 'react'
import { IoMdList } from 'react-icons/io'
import { RiEditFill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import { Place } from 'types/Place'

export const PartnerDetailsPage = () => {
  const navigate = useNavigate()
  const { partnerId } = useParams()
  const [partner, setPartner] = useState<Place>()
  const [isActive, setIsActive] = useState(0)

  useEffect(() => {
    if (!partnerId) return
    const partnerData = async () => {
      try {
        const res = await axios.get<{ place: Place }>(`places/${partnerId}`)
        const { place } = res.data
        setPartner(place)
      } catch (error) {
        console.log('select partner data error', error)
      }
    }
    partnerData()
  }, [partnerId])

  return (
    <div className="flex flex-col w-full h-full flex-1 p-5 mxl:p-0 mxl:py-5">
      <div className="flex items-center justify-between mb-2 h-12">
        <h2 className="text-2xl">{partner?.name ? partner.name : '-'}</h2>
        <div className="flex">
          <button
            className=" flex items-center text-blue-600 border border-blue-600 hover:bg-blue-800 hover:border-blue-700 hover:text-white px-2 py-1 rounded"
            onClick={() => {
              navigate('edit')
            }}>
            <RiEditFill />
            수정
          </button>

          <button
            className="flex items-center border rounded border-gray-400 text-gray-500 px-2 py-1 ml-2 hover:text-white hover:bg-gray-400"
            onClick={() => {
              navigate('/partner')
            }}>
            <IoMdList />
            목록보기
          </button>
        </div>
      </div>
      <div className="flex w-full py-5 border-y border-gray-300 h-28">
        <dl className="flex flex-col justify-center pr-5">
          <dt className="font-bold text-cent">반납센터명</dt>
          <dd className="">{partner ? partner.name : '-'}</dd>
        </dl>
        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold text-cent">전화번호</dt>
          <dd className="">
            {partner ? partner.phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : '-'}
          </dd>
        </dl>
        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold">주소</dt>
          <dd className="">
            {partner ? `${partner.address.address} ${partner.address.extraAddress}` : '-'}
          </dd>
          <dd>
            {partner ? `${partner.address.roadAddress} ${partner.address.extraAddress}` : '-'}
          </dd>
        </dl>
        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold">담당자</dt>
          <dd className="">
            {partner ? partner.roles.find(r => r.type === Roles.CHIEF)?.user?.name : '-'}
          </dd>
        </dl>
        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold">이메일</dt>
          <dd className="">{partner ? partner.email : '-'}</dd>
        </dl>
        <dl className="flex flex-col justify-center px-5">
          <dt className="font-bold">가입일</dt>
          <dd className="">{partner ? formatDateString(partner.createdAt) : '-'}</dd>
        </dl>
      </div>

      <div className="mxl:py-2 flex flex-col gap-5 mt-5">
        <div className="relative flex items-center justify-center gap-6 w-full">
          <button
            className={`text-xl w-auto rounded-md py-3 px-5 cursor-pointer relative bottom-[-1px] left-[-2px] hover:bg-blue-800 text-white${
              isActive === 0 ? ` bg-blue-600` : ` bg-gray-400`
            }`}
            onClick={() => setIsActive(0)}>
            입고목록
          </button>
        </div>
        <div className="flex-1 bg-white overflow-auto"></div>
      </div>
    </div>
  )
}
