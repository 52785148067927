import axios from 'api/axios'
import dayjs from 'dayjs'
import { Roles } from 'enums/Roles'
import { useFactory } from 'hooks/useFactory'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Address } from 'types/Address'
import { Place } from 'types/Place'

export const CentersPage = () => {
  const navigate = useNavigate()
  const { factory } = useFactory()
  const [centers, setCenters] = useState<Place[]>([])
  const fatchCenters = useCallback(async () => {
    if (!factory) return

    try {
      const res = await axios.get<{ places: Place[] }>('/places?type=center')
      const { places: centers } = res.data
      setCenters(centers.filter(c => c.factory?.id === factory.id))
    } catch (error) {
      console.log(error)
    }
  }, [factory])

  useEffect(() => {
    fatchCenters()
  }, [fatchCenters])

  return (
    <div className="w-full p-5">
      <h2 className="">반납센터</h2>

      <div className="overflow-hidden rounded mxl:rounded-none">
        <table className="w-full border rounded mt-2">
          <thead className="border-t border-b">
            <tr className="bg-gray-100">
              <th className="p-2 text-center">이름</th>
              <th className="p-2 text-center">대표</th>
              <th className="p-2 text-center">전화번호</th>
              <th className="p-2 text-center">회수매니저</th>
              <th className="p-2 text-center">보유팩</th>
              <th className="p-2 text-center">주소</th>
              <th className="p-2 text-center">가입일</th>
            </tr>
          </thead>

          <tbody className="w-full text-center">
            {centers.length === 0 && (
              <tr className="">
                <td colSpan={7} className="p-2 text-center">
                  지정된 반납센터가 없습니다. 관리자에게 문의하세요.
                </td>
              </tr>
            )}
            {centers.map(c => {
              const chief = c.roles.find(r => r.type === Roles.CHIEF)?.user
              return (
                <tr
                  className="hover:bg-gray-100 hover:cursor-pointer even:bg-gray-50"
                  key={c.id}
                  onClick={() => navigate(`/centers/${c.id}`)}>
                  <td className="p-2 text-center">{c.name}</td>
                  <td className="p-2 text-center">{chief?.name}</td>
                  <td className="p-2 text-center">{formatPhoneNumber(c.phoneNumber)}</td>
                  <td className="p-2 text-center">{c.retriever ? c.retriever.name : '-'}</td>
                  <td className="p-2 text-center">{c.packs.length}</td>
                  <td className="p-2 text-center">{formatAddress(c.address)}</td>
                  <td className="p-2 text-center">{formatDateString(c.createdAt)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export const formatDateString = (dateString: string) => dayjs(dateString).format('YYYY.MM.DD')

export const formatPhoneNumber = (pn: string) =>
  pn ? pn.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : '-'

export const formatAddress = (address: Address) =>
  `${address.roadAddress || address.address} ${address.extraAddress}`
